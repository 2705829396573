<template>
  <div class="relative">
    <Spin v-if="loading" fix></Spin>

    <div class="breadcrumb">
      <div class="item">
        Members
      </div>
      <Icon style="margin:0 5px" type="ios-arrow-forward" />
      <div class="item active">
        {{ member.first_name }} {{ member.last_name }}
      </div>
    </div>

    <div class="pd-20">
      <div class="member-name">
        {{ member.first_name }} {{ member.last_name }}
      </div>
      <Row :gutter="20">
        <i-col span="8">
          <div class="white-bg">
            <div class="img-box">
              <img v-if="member.avatar" :src="member.avatar.url" alt="" />
            </div>
          </div> </i-col
        ><i-col span="16">
          <div class="white-bg">
            <h2 class="divide">Basic Info</h2>

            <Row :gutter="20">
              <i-col span="12">
                <div class="k-text">NAME</div>
                <div class="text">
                  {{ member.first_name }} {{ member.last_name }}
                </div>
              </i-col>
              <i-col span="12">
                <div class="k-text">EMAIL</div>
                <div class="text">{{ member.email }}</div>
              </i-col>
              <i-col span="12">
                <div class="k-text">ROLE</div>
                <div class="text" v-if="member.is_administrator">
                  Administrator
                </div>
                <div class="text" v-else>Member</div>
              </i-col>
              <i-col span="12">
                <div class="k-text">JOB TITLE</div>
                <div class="text">{{ member.job_title }}</div>
              </i-col>
            </Row>
          </div>
        </i-col></Row
      >
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryMember } = api;

export default {
  components: {},
  created() {
    const id = this.$route.params.id;
    this.id = id;

    this.loading = true;

    _queryMember({ id })
      .then(response => {
        const { data } = response;
        this.member = data;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  data() {
    return {
      loading: false,
      member: {}
    };
  }
};
</script>

<style lang="less" scoped>
.breadcrumb {
  color: lighten(@primary-color, 30%);
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;

  .item {
    cursor: pointer;
    &.active {
      color: @primary-color;
      cursor: initial;
    }
  }
}

.white-bg {
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  padding: 20px;
}

.img-box {
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
  }
}

.member-name {
  color: #000;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  .float-btn {
    position: absolute;
    right: 20px;
  }
}

.divide {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.k-text {
  margin-bottom: 10px;
}
.text {
  width: 100%;
  overflow: hidden;
  background: #f7f7f7;
  padding: 6px 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  min-height: 34px;
}
</style>
